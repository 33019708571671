/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

class ContactPageComponent extends Component {
  state = {
    contactType: null
  };

  componentDidMount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`black`);
    appContext.setNavColor(`black`);
  }

  componentWillUnmount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`white`);
    appContext.setNavColor(`white`);
  }

  //

  render() {
    const { contactType } = this.state;
    const { documentContext, frontmatter, location } = this.props;
    const { device } = documentContext;

    let backgroundColor = `bg-white`;

    switch (contactType) {
      case `Melbourne`:
        backgroundColor = `bg-brown`;

        break;

      case `Sydney`:
        backgroundColor = `bg-olive`;

        break;

      case `Shanghai`:
        backgroundColor = `bg-pink`;

        break;

      default:
        break;
    }

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescriptions={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout
          className={`contact-page w-full relative ${
            device === `desktop` ? backgroundColor : ``
          }`}
          location={location}
        >
          <section className="contact-page__grid relative flex flex-col justify-between pt-24">
            <div className="grid">
              <div className="grid-end-8 xs:grid-end-24 grid-start-5 sm:grid-start-1 pb-32 xs:pb-4">
                <h3 className="flex items-start ml-8 pb-4 b1 text-black">
                  <span className="mr-2">—</span>
                  <span>Our offices</span>
                </h3>

                {device === `desktop` && (
                  <ul className="contact-page__links w-full relative block">
                    <li
                      key={frontmatter.officeTitleMelbourne}
                      className="contact-page__link transition-opacity my-4 py-2"
                    >
                      <button
                        type="button"
                        className="relative flex items-center"
                        onClick={() => {
                          this.setState({
                            contactType: frontmatter.officeTitleMelbourne
                          });
                        }}
                      >
                        <span className="block f2">
                          {frontmatter.officeTitleMelbourne}
                        </span>
                        {contactType === frontmatter.officeTitleMelbourne && (
                          <span className="animation-appear-right block ml-6 f3">
                            →
                          </span>
                        )}
                      </button>
                    </li>

                    <li
                      key={frontmatter.officeTitleSydney}
                      className="contact-page__link transition-opacity my-4 py-2"
                    >
                      <button
                        type="button"
                        className="relative flex items-center"
                        onClick={() => {
                          this.setState({
                            contactType: frontmatter.officeTitleSydney
                          });
                        }}
                      >
                        <span className="block f2">
                          {frontmatter.officeTitleSydney}
                        </span>
                        {contactType === frontmatter.officeTitleSydney && (
                          <span className="animation-appear-right block ml-6 f3">
                            →
                          </span>
                        )}
                      </button>
                    </li>

                    <li
                      key={frontmatter.officeTitleShanghai}
                      className="contact-page__link transition-opacity my-4 py-2"
                    >
                      <button
                        type="button"
                        className="relative flex items-center"
                        onClick={() => {
                          this.setState({
                            contactType: frontmatter.officeTitleShanghai
                          });
                        }}
                      >
                        <span className="block f2">
                          {frontmatter.officeTitleShanghai}
                        </span>
                        {contactType === frontmatter.officeTitleShanghai && (
                          <span className="animation-appear-right block ml-6 f3">
                            →
                          </span>
                        )}
                      </button>
                    </li>
                  </ul>
                )}
              </div>

              {(contactType === null || device !== `desktop`) && (
                <div className="animation-appear grid-end-9 xs:grid-end-22 grid-start-14 xs:grid-start-2">
                  <p
                    className="f4"
                    dangerouslySetInnerHTML={{ __html: frontmatter.intro }}
                  ></p>

                  <h4 className="mt-8 b3">Email</h4>

                  <p className="f4 xs:underline">
                    <a href={`mailto:${frontmatter.email}`}>
                      {frontmatter.email}
                    </a>
                  </p>
                </div>
              )}

              {device !== `desktop` && (
                <div className="services-page__expanders animation-appear-right grid-end-21 grid-start-4 sm:grid-end-22 sm:grid-start-2 xs:mt-6">
                  <ul>
                    <li
                      key={frontmatter.officeTitleMelbourne}
                      className="services-page__expander relative"
                    >
                      <button
                        type="button"
                        className="w-full relative flex items-center justify-between mt-8 f2"
                        onClick={() => {
                          this.setState(prevState => ({
                            contactType:
                              prevState.contactType === null
                                ? frontmatter.officeTitleMelbourne
                                : null
                          }));
                        }}
                      >
                        <span>{frontmatter.officeTitleMelbourne}</span>
                        <span>
                          {contactType === frontmatter.officeTitleMelbourne
                            ? `-`
                            : `+`}
                        </span>
                      </button>

                      {contactType === frontmatter.officeTitleMelbourne && (
                        <div className="animation-appear-down w-full relative pt-4 pb-8">
                          <p className="f4">
                            {frontmatter.officeDescriptionMelbourne}
                          </p>

                          <h4 className="mt-8 b3">Address</h4>
                          <p className="f4 underline">
                            {frontmatter.officeLocationMelbourne}
                          </p>

                          <h4 className="mt-8 b3">Phone number</h4>
                          <p className="f4 underline">
                            <a href={`tel:${frontmatter.officePhoneMelbourne}`}>
                              {frontmatter.officePhoneMelbourne}
                            </a>
                          </p>

                          <h4 className="mt-8 b3">Email</h4>
                          <p className="f4 underline">
                            <a
                              href={`mailto:${frontmatter.officeEmailMelbourne}`}
                            >
                              {frontmatter.officeEmailMelbourne}
                            </a>
                          </p>
                        </div>
                      )}
                    </li>

                    <li
                      key={frontmatter.officeTitleSydney}
                      className="services-page__expander relative"
                    >
                      <button
                        type="button"
                        className="w-full relative flex items-center justify-between mt-8 f2"
                        onClick={() => {
                          this.setState(prevState => ({
                            contactType:
                              prevState.contactType === null
                                ? frontmatter.officeTitleSydney
                                : null
                          }));
                        }}
                      >
                        <span>{frontmatter.officeTitleSydney}</span>
                        <span>
                          {contactType === frontmatter.officeTitleSydney
                            ? `-`
                            : `+`}
                        </span>
                      </button>

                      {contactType === frontmatter.officeTitleSydney && (
                        <div className="animation-appear-down w-full relative pt-4 pb-8">
                          <p className="f4">
                            {frontmatter.officeDescriptionSydney}
                          </p>

                          <h4 className="mt-8 b3">Address</h4>
                          <p className="f4 underline">
                            {frontmatter.officeLocationSydney}
                          </p>

                          <h4 className="mt-8 b3">Phone number</h4>
                          <p className="f4 underline">
                            <a href={`tel:${frontmatter.officePhoneSydney}`}>
                              {frontmatter.officePhoneSydney}
                            </a>
                          </p>

                          <h4 className="mt-8 b3">Email</h4>
                          <p className="f4 underline">
                            <a href={`mailto:${frontmatter.officeEmailSydney}`}>
                              {frontmatter.officeEmailSydney}
                            </a>
                          </p>
                        </div>
                      )}
                    </li>

                    <li
                      key={frontmatter.officeTitleShanghai}
                      className="services-page__expander relative"
                    >
                      <button
                        type="button"
                        className="w-full relative flex items-center justify-between mt-8 f2"
                        onClick={() => {
                          this.setState(prevState => ({
                            contactType:
                              prevState.contactType === null
                                ? frontmatter.officeTitleShanghai
                                : null
                          }));
                        }}
                      >
                        <span>{frontmatter.officeTitleShanghai}</span>
                        <span>
                          {contactType === frontmatter.officeTitleShanghai
                            ? `-`
                            : `+`}
                        </span>
                      </button>

                      {contactType === frontmatter.officeTitleShanghai && (
                        <div className="animation-appear-down w-full relative pt-4 pb-8">
                          <p className="f4">
                            {frontmatter.officeDescriptionShanghai}
                          </p>

                          <h4 className="mt-8 b3">Address</h4>
                          <p className="f4 underline">
                            {frontmatter.officeLocationShanghai}
                          </p>

                          <h4 className="mt-8 b3">Phone number</h4>
                          <p className="f4 underline">
                            <a href={`tel:${frontmatter.officePhoneShanghai}`}>
                              {frontmatter.officePhoneShanghai}
                            </a>
                          </p>

                          <h4 className="mt-8 b3">Email</h4>
                          <p className="f4 underline">
                            <a
                              href={`mailto:${frontmatter.officeEmailShanghai}`}
                            >
                              {frontmatter.officeEmailShanghai}
                            </a>
                          </p>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              )}

              {device === `desktop` && (
                <>
                  {contactType === frontmatter.officeTitleMelbourne && (
                    <div className="animation-appear grid-end-9 grid-start-14">
                      <p className="f4">
                        {frontmatter.officeDescriptionMelbourne}
                      </p>

                      <h4 className="mt-8 b3">Address</h4>
                      <p className="f4">
                        {frontmatter.officeLocationMelbourne}
                      </p>

                      <h4 className="mt-8 b3">Phone number</h4>
                      <p className="f4">
                        <a href={`tel:${frontmatter.officePhoneMelbourne}`}>
                          {frontmatter.officePhoneMelbourne}
                        </a>
                      </p>

                      <h4 className="mt-8 b3">Email</h4>
                      <p className="f4">
                        <a href={`mailto:${frontmatter.officeEmailMelbourne}`}>
                          {frontmatter.officeEmailMelbourne}
                        </a>
                      </p>
                    </div>
                  )}

                  {contactType === frontmatter.officeTitleSydney && (
                    <div className="animation-appear grid-end-9 grid-start-14">
                      <p className="f4">
                        {frontmatter.officeDescriptionSydney}
                      </p>

                      <h4 className="mt-8 b3">Address</h4>
                      <p className="f4">{frontmatter.officeLocationSydney}</p>

                      <h4 className="mt-8 b3">Phone number</h4>
                      <p className="f4">
                        <a href={`tel:${frontmatter.officePhoneSydney}`}>
                          {frontmatter.officePhoneSydney}
                        </a>
                      </p>

                      <h4 className="mt-8 b3">Email</h4>
                      <p className="f4">
                        <a href={`mailto:${frontmatter.officeEmailSydney}`}>
                          {frontmatter.officeEmailSydney}
                        </a>
                      </p>
                    </div>
                  )}

                  {contactType === frontmatter.officeTitleShanghai && (
                    <div className="animation-appear grid-end-9 grid-start-14">
                      <p className="f4">
                        {frontmatter.officeDescriptionShanghai}
                      </p>

                      <h4 className="mt-8 b3">Address</h4>
                      <p className="f4">{frontmatter.officeLocationShanghai}</p>

                      <h4 className="mt-8 b3">Phone number</h4>
                      <p className="f4">
                        <a href={`tel:${frontmatter.officePhoneShanghai}`}>
                          {frontmatter.officePhoneShanghai}
                        </a>
                      </p>

                      <h4 className="mt-8 b3">Email</h4>
                      <p className="f4">
                        <a href={`mailto:${frontmatter.officeEmailShanghai}`}>
                          {frontmatter.officeEmailShanghai}
                        </a>
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="grid">
              <ul className="grid-end-20 sm:grid-end-22 grid-start-5 sm:grid-start-2 relative flex justify-end sm:block mb-2 sm:my-24">
                <li className="ml-24 xs:ml-0 py-4 f4">
                  <a
                    href="https://www.instagram.com/paper_stone_scissors/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>

                <li className="ml-24 xs:ml-0 py-4 f4">
                  <a
                    href="https://www.linkedin.com/company/paper-stone-scissors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

//

const ContactPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <ContactPageComponent
      appContext={appContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      location={location}
    />
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        email
        intro
        officeTitleMelbourne
        officeDescriptionMelbourne
        officeLocationMelbourne
        officePhoneMelbourne
        officeEmailMelbourne
        officeTitleSydney
        officeDescriptionSydney
        officeLocationSydney
        officePhoneSydney
        officeEmailSydney
        officeTitleShanghai
        officeDescriptionShanghai
        officeLocationShanghai
        officePhoneShanghai
        officeEmailShanghai
        seoDescription
        seoKeywords
      }
    }
  }
`;
